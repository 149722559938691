import {
  createStandaloneToast,
  Icon,
  Text,
  ToastPosition,
} from "@chakra-ui/react";
import { fontDecider } from "@util/font-decider";
import { ReactNode } from "react";
import { IconType } from "react-icons/lib";
import { MdCheckCircleOutline } from "react-icons/md";
import { theme } from "../theme";

const position = "top";
const duration = 9000;
const isClosable = true;

const { toast } = createStandaloneToast({ theme });

export const toaster = {
  success(message: ReactNode, title = "", positionManual?: ToastPosition) {
    toast.closeAll();
    toast({
      title,
      description: message,
      status: "success",
      position: positionManual ? positionManual : position,
      duration,
      isClosable,
    });
  },
  closeAll() {
    toast.closeAll();
  },
  info(message: string, title = "") {
    toast.closeAll();
    toast({
      title,
      description: message,
      status: "info",
      position,
      duration,
      isClosable,
    });
  },
  warning(message: string, title = "") {
    toast.closeAll();
    toast({
      title,
      description: message,
      status: "warning",
      position,
      duration,
      isClosable,
    });
  },
  error(message: string, title = "") {
    toast.closeAll();
    toast({
      title,
      description: message,
      status: "error",
      position,
      duration,
      isClosable,
    });
  },
  nourish(message: string, icon: IconType, lang: string) {
    toast.closeAll();
    toast({
      title: "",
      description: message,
      status: "warning",
      position: "bottom",
      duration,
      isClosable,
      icon: <Icon as={icon} m={1} fontSize="md" />,
      containerStyle: {
        pos: "absolute",
        bottom: 20,
        fontSize: 13,
        fontWeight: "bold",
        fontFamily: fontDecider(lang),
      },
    });
  },
  newMessageToaster(
    descText: string,
    actionText: string,
    goToMessage: () => void,
    setMarkAsRead: () => void
  ) {
    toast.closeAll();
    toast({
      title: null,
      icon: <Icon as={MdCheckCircleOutline} />,
      description: (
        <>
          <Text fontWeight={"700"} as="span" fontSize="13px">
            {descText}
          </Text>{" "}
          <Text
            borderBottom="1px solid white"
            onClick={() => {
              goToMessage();
              setMarkAsRead();
              toast.closeAll();
            }}
            fontWeight={"700"}
            as="span"
            fontSize="13px"
          >
            {actionText}
          </Text>
        </>
      ),
      status: "success",
      position: position,
      duration: null,
      onCloseComplete: () => {
        setMarkAsRead();
      },
      isClosable: true,
    });
  },
};
