import { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
  borderRadius: "3px",
};

const sizes: Record<string, SystemStyleObject> = {
  md: {
    px: "24px",
  },
};

const variants = {
  reviewLike: {
    border: "1px",
    h: 8,
    orderColor: "pink.600",
    color: "pink.600",
    bg: "#f5f7ff",
    borderRadius: "32px",
    w: 14,
    _active: {
      backgroundColor: "pink.500",
      color: "white",
    },
  },
  gray: {
    bg: "gray.100",
    borderColor: "gray.200",
    borderWidth: "1px",
    _light: {
      color: "black",
    },
    _hover: {
      bg: "gray.300",
      color: "gray.800",
    },
  },
  solid: {
    color: "white",
    bg: "nourishGreen.500",
    _active: {
      bg: "nourishGreen.700",
    },
    _disabled: {
      cursor: "pointer",
    },
    _hover: {
      bg: "nourishGreen.300",
      _disabled: {
        bg: "nourishGreen.500",
      },
    },
  },
  bankSolid: {
    h: "20px",
    color: "gray.700",

    fontSize: "11px",
    bg: "#fff",
    _active: {
      bg: "nourishGreen.700",
    },
    _disabled: {
      cursor: "pointer",
    },
    _hover: {
      bg: "nourishGreen.300",
      _disabled: {
        bg: "nourishGreen.500",
      },
    },
    borderRadius: "16px",
    border: "1px",
    borderColor: "nourishGreen.400",
  },
  delete: {
    bg: "red.500",
    _light: {
      color: "white",
    },
    _hover: {
      bg: "red.300",
    },
  },
  blue: {
    color: "white",
    bg: "blue.600",
    _disabled: {
      cursor: "pointer",
    },
    _hover: {
      bg: "blue.700",
      _disabled: {
        bg: "blue.600",
      },
    },
    size: "md",
    borderColor: "blue.600",
  },

  bluelined: {
    color: "blue.600",
    bg: "offWhite",
    border: "1px",
    _hover: {
      bg: "blue.700",
    },
    size: "md",
    borderColor: "blue.600",
  },
  outline: {
    color: "green.500",
    bg: "transparent",
    _hover: {
      bg: "green.900",
    },
    size: "md",
    borderColor: "green.500",
  },
  outlineFileAttach: {
    color: "gray.500",
    bg: "transparent",
    _hover: {
      bg: "blue.700",
      color: "white",
    },
    w: "full",
    fontWeight: 300,
    size: "md",
    border: "1px",
    borderColor: "gray.100",
    dropShadow: "black.100",
    paddingStart: 3,
  },
  outlineFilePreview: {
    color: "gray.500",
    bg: "transparent",
    _hover: {
      bg: "blue.700",
      color: "white",
    },
    fontWeight: 300,
    size: "md",
    border: "1px",
    borderColor: "gray.100",
    dropShadow: "black.100",
    paddingStart: 3,
  },
  outlinelight: {
    color: "white",
    bg: "transparent",
    _hover: {
      bg: "green.900",
    },
    size: "sm",
    borderColor: "green.500",
    fontWeight: "100",
    borderWidth: "1px",
  },
  control: {
    bg: "green.500",
    _hover: {
      bg: "green.300",
    },
    size: "md",
    borderColor: "green.500",
    color: "white",
  },
  contact: {
    bg: "green.500",
    _hover: {
      bg: "green.300",
    },
    border: "solid 1px",
    borderColor: "green.300",
    color: "white",
    width: "full",
    mx: "6",
    height: "12",
    borderRadius: "4",
    fontWeight: "800",
    fontSize: "lg",
  },
  info: {
    size: "md",
    borderRadius: "20px",
    bg: "gray.850",
    _hover: {
      bg: "gray.850",
    },
    color: "gray.400",
    fontWeight: "400",
    px: 3,
  },
  add: {
    height: "31px",
    px: 5,
    bg: "green.500",
    _light: {
      color: "white",
    },
    _hover: {
      bg: "green.300",
    },
  },
  greentransparent: {
    bg: "green.10",
    borderRadius: "20px",
    border: "1px",
    color: "green.600",
    fontSize: "12px",
    lineHeight: "15px",
    borderColor: "green.50",
  },
  rounded: {
    size: "md",
    borderRadius: "32px",
    bg: "green.500",
    border: "1px",
    borderColor: "green.600",
    color: "white",
    h: "46px",
    fontWeight: 900,
    px: 5,
    py: 3,
  },
  bigrounded: {
    size: "md",
    backgroundColor: "green.500",
    border: "2px",
    borderColor: "green.600",
    borderRadius: "32px",
    fontSize: "xl",
    fontWeight: "black",
    color: "white",
    h: "46px",
    px: 5,
    py: 3,
  },
  nourishOnboarding: {
    fontFamily: "inherit",
    size: "md",
    backgroundColor: "nourishGreen.500",
    borderRadius: "4px",
    fontWeight: "800",
    fontSize: "16px",
    lineheight: "20px",
    color: "#FFFFFF",
    _hover: { _disabled: { bg: "nourishGreen.500" } },
    h: "10",
    px: 4,
    py: 3,
  },
  nourishOnboardingBig: {
    h: "auto",
    fontFamily: "inherit",
    backgroundColor: "nourishGreen.500",
    borderRadius: "5px",
    fontWeight: "900",
    fontSize: "20px",
    color: "white",
    _hover: { _disabled: { bg: "nourishGreen.500" } },
    px: 7,
    py: 4,
  },
  nourishOnboardingSecondary: {
    fontFamily: "inherit",
    size: "md",
    border: "2px",
    borderColor: "nourishGreen.100",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "15px",
    color: "nourishGreen.500",
    h: "10",
    px: 3,
    py: 2,
  },
  nourishOutline: {
    fontFamily: "inherit",
    size: "md",
    backgroundColor: "offWhite",
    border: "1px",
    borderColor: "nourishGreen.500",
    borderRadius: "4px",
    fontWeight: "600",
    fontSize: "11px",
    color: "nourishGreen.500",
    h: "10",
    px: 4,
    py: 2,
    boxShadow: "0px 1px 8px 0px rgba(14, 30, 47, 0.03);",
  },
  nourishIntake: {
    h: "auto",
    px: 6,
    py: 4,
    color: "white",
    backgroundColor: "nourishGreen.500",
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 800,
    borderRadius: "5px",
    _hover: { _disabled: { bg: "nourishGreen.500" } },
  },
  nourishIntakeOutline: {
    h: "auto",
    px: 6,
    py: 4,
    color: "nourishGreen.500",
    backgroundColor: "F9F6F4",
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 500,
    borderWidth: 1,
    borderColor: "nourishGreen.500",
    borderRadius: "5px",
    _hover: { _disabled: { bg: "F9F6F4" } },
  },
  nourishIntakeWhite: {
    h: "auto",
    px: 6,
    py: 4,
    color: "nourishGreen.500",
    backgroundColor: "offWhite",
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 800,
    borderWidth: 1,
    borderColor: "nourishGreen.500",
    fontFamily: "inherit",
    borderRadius: "5px",
    _hover: { _disabled: { bg: "F9F6F4" } },
  },
  nourishSubmit: {
    fontFamily: "inherit",
    textTransform: "capitalize",
    backgroundColor: "nourishGreen.500",
    border: "2px",
    borderColor: "nourishGreen.500",
    borderRadius: "4px",
    fontWeight: "800",
    fontSize: "16px",
    w: "full",
    color: "offWhite",
    lineHeight: "20px",
    px: 4,
    py: 3,
    boxShadow: "0px 1px 8px 0px rgba(14, 30, 47, 0.03);",
  },
  nourishX: {
    size: "md",
    backgroundColor: "transparent",
    border: "0px",
    fontWeight: "600",
    fontSize: "11px",
    color: "nourishGreen.500",
    h: "10",
    px: 0,
    py: 0,
  },
  nourishOrange: {
    bg: "orange.600",
    _hover: {
      bg: "orange.300",
    },
    _active: {
      bg: "orange.500",
    },
    border: "solid 1px",
    borderColor: "orange.600",
    color: "white",
    width: "full",
    borderRadius: "5",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "20px",
  },
  nourishOrangeOutline: {
    bg: "#FCFDFF",
    _hover: {
      bg: "orange.25",
    },
    _active: {
      bg: "orange.50",
    },
    border: "solid 1px",
    borderColor: "orange.50",
    color: "orange.600",
    width: "full",
    borderRadius: "5",
    fontSize: "14px",
    lineHeight: "20px",
  },
  remove: {
    height: "31px",
    px: 2,
    borderLeftRadius: 0,
    bg: "red.500",
    size: "sm",
    _light: {
      color: "white",
    },
    _hover: {
      bg: "red.300",
    },
  },
};

const defaultProps = {
  size: "md",
  variant: "solid",
  casing: "capitalize",
};

const Button = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export default Button;
